import React, { useCallback, useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import ImportView from "./view";
import { PageContext } from "../../lib/context";
import {
  ADMIN_BM_ESTIMATES,
  AGGREGATES,
  ALLOCATIONS,
  USERS_SEARCH,
} from "../../lib/query";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import { useMemo } from "react";
import _ from "lodash";
import { getSignedImageUrl } from "../../lib/util";
import CSVReader from "react-csv-reader";
import { INSERT_IMPORT_USERS } from "../../lib/mutations";
import { useContext } from "react";
import { AppContext } from "../../lib/context";
import { USER_INFO } from "../../lib/subscriptions";
import { USER_DATA } from "../../lib/query";


const ImportController = (props) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const searchTextDebouncer = useCallback(_.debounce(setSearch, 500), []);
  const [importedData, setImportedData] = useState([]);
  const [count,setCount] = useState(0)
  const [disable, setDisable] = useState(true)
  const [usergroup, setUserGroup] = useState();
  
  const {APP_TOKEN} = useContext(AppContext);

  const { data: user_data, loading: user_loading } = useQuery(USER_DATA);

  const [add]= useMutation(INSERT_IMPORT_USERS);


  useEffect(()=>{
    setUserGroup(user_data?.portal_users?.[0]?.group)
    
  console.log(Math.floor(100000 + Math.random() * 900000));
  },[user_data]);

  
const submit = async ()=>{
  setDisable(true)

  const response = await add({
    variables:{
      objects:[...importedData]
    }
  })
  if (response?.data?.insert_users?.returning?.length > 0){
    notification.success({
      message: "Success"
    })
    setDisable(true)
  }else{
    notification.error({
      message: "Insertion Failed",
      //description: "Incorrect username or password",
    });
  }
}
    
  const pageValues = {
    setImportedData,
    submit,
    setCount,
    count,
    setDisable,
    disable,
    importedData,
    usergroup,
  };

  return (
    <PageContext.Provider value={pageValues}>
          <ImportView />
    </PageContext.Provider>
  );
};

export default ImportController;
