import React, { useMemo, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  ADMIN_BM_ESTIMATES,
  ADMIN_CPG_USERS,
  ADMIN_MERCHANTS,
  AGGREGATES,
  ALLOCATIONS,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { formatMoney } from "./table-columns";

const AdminReportsController = (props) => {
  const { data, loading } = useQuery(AGGREGATES);
  const [csvData, setCsvData] = useState([
    [
      "Username",
      "Pending",
      "Accepted",
      "Screened",
      "Approved",
      "Total Applications",
      "Unprocessed Amount",
      "Pending Amount",
      "Accepted Amount",
      "Screened Amount",
      "Approved Amount",
    ],
  ]);
  const [csvMData, setCsvMData] = useState([
    ["Merchant Name", "Transactions", "Total Billings"],
  ]);

  const [csvCpgData, setCsvCpgData] = useState([
    ["First Name", "Last name", "Transactions", "Balance"],
  ]);

  const [csvSummary, setCsvSummary] = useState([
    [
      "Assisting Office",
      "Category A",
      "Category B",
      "Category C",
      "Approved",
      "Applicants",
      "Amount",
      "Percent/Allocated",
      "Amount Spent",
      "Balance"
    ],
  ]);

  const [allocations, setAllocations] = useState({
    pending: 0,
    accepted: 0,
    approved: 0,
    unproccessed: 0,
  });

  const { data: bm_data, loading: bm_loading } = useQuery(ADMIN_BM_ESTIMATES);
  const { data: m_data, loading: m_loading } = useQuery(ADMIN_MERCHANTS);
  const { data: cpg_data, loading: cpg_loading } = useQuery(ADMIN_CPG_USERS);

  const { data: allocations_data } = useQuery(ALLOCATIONS);

  useMemo(() => {
    if (allocations_data) {
      const pending = _.sumBy(allocations_data?.pending, (o) => {
        return _.sumBy(o.user_items, (item) => item.unit_price);
      });
      const accepted = _.sumBy(allocations_data?.accepted, (o) => {
        return o.wallet_balance;
      });
      const approved = _.sumBy(allocations_data?.approved, (o) => {
        return o.wallet_balance;
      });
      const unproccessed = pending + accepted;
      setAllocations({
        ...allocations,
        accepted,
        pending,
        approved,
        unproccessed,
      });
    }
  }, [allocations_data]);

  useMemo(() => {
    if (bm_data) {
      if (bm_data?.portal_users?.length) {
        const csvItems = [];
        if (!bm_loading) {
          const bm_length = bm_data?.portal_users?.length;
          for (let i = 0; i < bm_length; i++) {
            const item = bm_data?.portal_users?.[i];
            const data = [
              item?.username,
              item?.total_pending?.aggregate?.count,
              item?.total_accepted?.aggregate?.count,
              item?.total_screened?.aggregate?.count,
              item?.total_approved?.aggregate?.count,
              item?.total_applications?.aggregate?.count,
              formatMoney(
                item?.pending_cat_a_count?.aggregate?.count * 50000 +
                  item?.pending_cat_b_count?.aggregate?.count * 20000 +
                  item?.pending_cat_c_count?.aggregate?.count * 10000 +
                  item?.accepted_allocation?.aggregate?.sum?.wallet_balance
              ),
              formatMoney(
                item?.pending_cat_a_count?.aggregate?.count * 50000 +
                  item?.pending_cat_b_count?.aggregate?.count * 20000 +
                  item?.pending_cat_c_count?.aggregate?.count * 10000
              ),
              formatMoney(
                item?.accepted_allocation?.aggregate?.sum?.wallet_balance
              ),
              formatMoney(
                item?.approved_allocation?.aggregate?.sum?.wallet_balance
              ),
              formatMoney(
                item?.approved_allocation?.aggregate?.sum?.wallet_balance
              ),
            ];
            csvItems.push(data);
            if (i === bm_length - 1) {
              // console.log("items", csvItems);
              setCsvData([...csvData, ...csvItems]);
            }
          }
        }
      }
    }
  }, [bm_loading, setCsvData]);

  useMemo(() => {
    if (m_data) {
      if (m_data?.merchants?.length) {
        const csvItems = [];
        if (!m_loading) {
          const m_length = m_data?.merchants?.length;
          for (let i = 0; i < m_length; i++) {
            const item = m_data?.merchants?.[i];
            const data = [
              item?.name,
              item?.merchants_sales_aggregate?.aggregate?.count,
              item?.merchants_sales_aggregate?.aggregate?.sum?.amount ?? 0,
            ];
            csvItems.push(data);
            if (i === m_length - 1) {
              setCsvMData([...csvMData, ...csvItems]);
            }
          }
        }
      }
    }
  }, [m_data, setCsvMData]);

  useMemo(() => {
    if (cpg_data) {
      if (cpg_data?.users?.length) {
        const csvItems = [];
        if (!cpg_loading) {
          const cpg_length = cpg_data?.users?.length;
          let temp_items_length = 0;
          const columns = [...csvCpgData[0]];
          for (let i = 0; i < cpg_length; i++) {
            const item = cpg_data?.users?.[i];
            const items_length = item?.user_items?.length;
            let length;
            if (items_length > temp_items_length)
              length = items_length - temp_items_length;
            for (let a = 0; a < length; a++) {
              columns.push(`Item no. ${temp_items_length + a + 1}`);
              if (a === items_length - 1) temp_items_length = items_length;
            }
            if (i === cpg_length - 1) {
              csvItems.push(columns);
              console.log("colums", csvItems);
              for (let j = 0; j < cpg_length; j++) {
                const item = cpg_data?.users?.[j];
                const data = [
                  item?.first_name,
                  item?.last_name,
                  item?.merchants_sales_aggregate?.aggregate?.count,
                  formatMoney(
                    item?.merchants_sales_aggregate?.aggregate?.sum?.amount ?? 0
                  ),
                ];
                const items_length = item?.user_items?.length;
                if (items_length > 0) {
                  for (let a = 0; a < items_length; a++) {
                    data.push(
                      `Item: ${item?.user_items?.[a]?.item?.replace(/,|'|"/g, '')} \nQuantity: ${
                        item?.user_items?.[a]?.quantity ?? 0
                      } \nUnit Price${
                        item?.user_items?.[a]?.unit_price ?? 0
                      } \nCost: ${
                        item?.user_items?.[a]?.cost ?? 0
                      } \nSupplier: ${
                        item?.user_items?.[a]?.suggested_supplier_vendor?.replace(/,|'|"/g, '') ?? "N/A"
                      }`
                    );
                    if (a === items_length - 1) csvItems.push(data);
                  }
                } else {
                  csvItems.push(data);
                }
                if (j === cpg_length - 1) {
                  setCsvCpgData([...csvItems]);
                }
              }
            }
          }
        }
      }
    }
  }, [cpg_data, setCsvCpgData]);

  useMemo(() => {
    if (bm_data) {
      if (bm_data?.portal_users?.length) {
        const csvItems = [];
        if (!bm_loading) {
          const bm_length = bm_data?.portal_users?.length;
          for (let i = 0; i < bm_length; i++) {
            const item = bm_data?.portal_users?.[i];
            let total_allocation;
            total_allocation = item?.username === "bm.vgo" ? 15000000 : 5000000;
            const data = [
              item?.group,
              item?.cat_a_count?.aggregate?.count,
              item?.cat_b_count?.aggregate?.count,
              item?.cat_c_count?.aggregate?.count,
              item?.cat_a_count?.aggregate?.count,
              item?.total_approved?.aggregate?.count,
              item?.cat_a_count?.aggregate?.count +
                item?.cat_b_count?.aggregate?.count +
                item?.cat_c_count?.aggregate?.count,
              formatMoney(
                item?.cat_a_pending?.aggregate?.count * 50000 +
                  item?.cat_b_pending?.aggregate?.count * 20000 +
                  item?.cat_c_pending?.aggregate?.count * 10000 +
                  item?.cat_a_screened?.aggregate?.count * 50000 +
                  item?.cat_b_screened?.aggregate?.count * 20000 +
                  item?.cat_c_screened?.aggregate?.count * 10000 +
                  item?.cat_a_accept?.aggregate?.count * 50000 +
                  item?.cat_b_accept?.aggregate?.count * 20000 +
                  item?.cat_c_accept?.aggregate?.count * 10000 +
                  item?.cat_a_approved?.aggregate?.count * 50000 +
                  item?.cat_b_approved?.aggregate?.count * 20000 +
                  item?.cat_c_approved?.aggregate?.count * 10000
              ),
              (
                ((item?.cat_a_pending?.aggregate?.count * 50000 +
                  item?.cat_b_pending?.aggregate?.count * 20000 +
                  item?.cat_c_pending?.aggregate?.count * 10000 +
                  item?.cat_a_screened?.aggregate?.count * 50000 +
                  item?.cat_b_screened?.aggregate?.count * 20000 +
                  item?.cat_c_screened?.aggregate?.count * 10000 +
                  item?.cat_a_accept?.aggregate?.count * 50000 +
                  item?.cat_b_accept?.aggregate?.count * 20000 +
                  item?.cat_c_accept?.aggregate?.count * 10000 +
                  item?.cat_a_approved?.aggregate?.count * 50000 +
                  item?.cat_b_approved?.aggregate?.count * 20000 +
                  item?.cat_c_approved?.aggregate?.count * 10000) /
                  total_allocation) *
                100
              ).toFixed(2) + "%",
              formatMoney(
                _.sumBy(item?.group_aggregate?.nodes, 
                  function(o){
                    return o?.merchants_sales_aggregate?.aggregate?.sum?.amount
                  }
                )??0
              ),
              formatMoney(
                _.sumBy(item?.balance,(o)=>{
                  let balance = o.wallet_balance
                  let category = o.category
                  if (balance === null) {
                    switch (category) {
                      case 'a':
                        balance = 50000
                        break;
                      case 'b':
                        balance = 20000
                        break;
                      default:
                        balance = 10000
                        break;
                    }
                    return balance
                  } else {
                    return balance
                  }
                })??0
              )
            ];
            csvItems.push(data);
            if (i === bm_length - 1) {
              // console.log("items", csvItems);
              setCsvSummary([...csvSummary, ...csvItems]);
            }
          }
        }
      }
    }
  }, [bm_loading, setCsvSummary]);

  const pageValues = {
    data,
    loading,
    bm_data,
    bm_loading,
    m_data,
    m_loading,
    cpg_data,
    cpg_loading,
    allocations,
    csvData,
    allocations_data,
    csvMData,
    csvCpgData,
    csvSummary,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default AdminReportsController;
