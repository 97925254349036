import React, { useCallback, useState, useEffect } from "react";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  GET_ASSIGNED_GROUP,
  BARANGAYS,
  BULK_USERS_SEARCH,
} from "../../lib/query";
import { useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import _ from "lodash";
import { getSignedImageUrl } from "../../lib/util";
// import { UPSERT_USERS } from "../../lib/mutations";
import { Offices } from "../show-details/components/offices";

const BulkPrintController = () => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const searchTextDebouncer = useCallback(_.debounce(setSearch, 500), []);
  const [groupName, setGroupName] = useState(Offices[0].OFFICE);
  const [checker, setChecker] = useState(false);
  const [status, setStatus] = useState("approved");
  const [barangay, setBarangay] = useState("CALERO");
  const [category, setCategory] = useState("TOTALLY DAMAGED");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const { data, loading } = useQuery(BULK_USERS_SEARCH, {
    variables: {
      municipality: groupName,
      status,
      // barangay,
      // category,
    },
  });

  const { data: assigned_group } = useQuery(GET_ASSIGNED_GROUP);
  const { data: barangays } = useQuery(BARANGAYS);

  useMemo(() => {
    if (data && !loading) {
      //console.log("data", data);
      if (data?.users?.length) {
        let totalPage = data?.users?.length / 200;
        if (data?.users?.length % 200 !== 0) totalPage += 1;
        setTotalPage(Math.floor(totalPage));

        const array = [];
        const timer = ms => new Promise(res => setTimeout(res, ms))
        // async function load() { // We need to wrap the loop into an async function for this to work
        //   for (var i = 0; i < data.users.length; i++) {
        //     const user = data.users[i]
        //     array.push({ ...user });
        //     setOptions([...array]);
        //     // if (i === data?.users?.length - 1) {
        //     //   console.log("arr", array);
        //     //   setOptions([...user]);
        //     // }
        //     await timer(50); // then the created Promise can be awaited
        //   }
        // }
        // load()
        data.users.map((user, key) => {
          array.push({ ...user });
          if (key === data?.users?.length - 1) {
            console.log("arr", array);
            setOptions([...array]);
          }
        });
      } else {
        setOptions([]);
      }
    }
  }, [data]);

  useMemo(() => {
    console.log("page", page);
    console.log(
      (page - 1) * 200,
      (page - 1) * 200 +
      (page === totalPage ? (options.length % 200) - 1 : 199)
    );
  }, [page]);

  const onSearch = (searchText) => {
    searchTextDebouncer("%" + searchText + "%");
    setValue(searchText);
  };

  const filterInput = (data) => {
    setGroupName(data);
  };

  const onSelect = async (data) => {
    let parsed = JSON.parse(data);
    let user_picture = await getSignedImageUrl(parsed.user_picture);
    setSelected([...selected, { ...parsed, user_picture }]);
    setValue(null);
  };

  const handleDelete = (key) => {
    setSelected(
      selected.filter(function(_, index) {
        return index !== key;
      })
    );
  };

  useEffect(() => {
    if (data?.length > 0) {
      setChecker(true);
    } else {
      setChecker(false);
    }
  }, [assigned_group]);


  // const [insertUser] = useMutation(UPSERT_USERS);

  // const prepObject = async (data) => {
  // 	const obj = [];
  // 	data.map((item) => {
  // 		if (item[2] != undefined) {
  // 			let i = {
  // 				first_name: item[2],
  // 				last_name: item[1],
  // 				current_location: item[5],
  // 			};
  // 			obj.push(i);
  // 		}
  // 	});
  // 	console.log("data", obj);
  // 	await insertUser({
  // 		variables: {
  // 			object: [...obj],
  // 		},
  // 	});
  // };

  const pageValues = {
    data,
    loading,
    options,
    onSearch,
    selected,
    setSelected,
    onSelect,
    value,
    setValue,
    handleDelete,
    assigned_group,
    filterInput,
    groupName,
    checker,
    setStatus,
    setBarangay,
    barangay,
    barangays,
    category,
    setCategory,
    page,
    setPage,
    setTotalPage,
    totalPage,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default BulkPrintController;
