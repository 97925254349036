import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin } from "antd";

const RegistrationView = (props) => {
  const { form, handleFinishForm, loading } = useContext(PageContext);
  return (
    <div className="flex flex-col h-screen items-center pt-32">
      <img
        src={require("../../assets/cebu-province-logo.jpg")}
        style={{ height: 150, width: 150 }}
      />
      <p className="font-bold text-xl">WePay PH</p>
      <p className="font-bold text-xl">CPG Portal Login</p>
      <div>
        <Form layout="vertical" form={form} onFinish={handleFinishForm}>
          <Form.Item label="Username" name="email" required>
            <Input placeholder="Enter username" />
          </Form.Item>
          <Form.Item label="Password" name="password" required>
            <Input.Password placeholder="password" />
          </Form.Item>
          <Button disabled={loading} type="primary" htmlType="submit">
            {loading ? <Spin/> : "Login"}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default RegistrationView;
