import React from "react";
import _ from 'lodash'

export const formatMoney = (money) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "php",
  }).format(money);
};

export const bm_columns = [
  {
    title: "Username",
    key: "username",
    dataIndex: "username",
    fixed: "left",
  },
  {
    title: "Pending",
    render: (item) => {
      return <p>{item?.total_pending?.aggregate?.count}</p>;
    },
  },
  {
    title: "Accepted",
    render: (item) => {
      return <p>{item?.total_accepted?.aggregate?.count}</p>;
    },
  },
  {
    title: "Screened",
    render: (item) => {
      return <p>{item?.total_screened?.aggregate?.count}</p>;
    },
  },
  {
    title: "Approved",
    render: (item) => {
      return <p>{item?.total_approved?.aggregate?.count}</p>;
    },
  },
  {
    title: "Total Applications",
    render: (item) => {
      return <p>{item?.total_applications?.aggregate?.count}</p>;
    },
  },
  {
    title: "Unprocessed Amount",
    render: (item) => {
      return (
        <p>
          {formatMoney(
            item?.pending_cat_a_count?.aggregate?.count * 50000 +
              item?.pending_cat_b_count?.aggregate?.count * 20000 +
              item?.pending_cat_c_count?.aggregate?.count * 10000 +
              item?.accepted_allocation?.aggregate?.sum?.wallet_balance
          )}
        </p>
      );
    },
  },
  {
    title: "Pending Amount",
    render: (item) => {
      return (
        <p>
          {formatMoney(
            item?.pending_cat_a_count?.aggregate?.count * 50000 +
              item?.pending_cat_b_count?.aggregate?.count * 20000 +
              item?.pending_cat_c_count?.aggregate?.count * 10000
          )}
        </p>
      );
    },
  },
  {
    title: "Accepted Amount",
    render: (item) => {
      return (
        <p>
          {formatMoney(
            item?.accepted_allocation?.aggregate?.sum?.wallet_balance
          )}
        </p>
      );
    },
  },
  {
    title: "Screened Amount",
    render: (item) => {
      return (
        <p>
          {formatMoney(
            item?.approved_allocation?.aggregate?.sum?.wallet_balance
          )}
        </p>
      );
    },
  },
  {
    title: "Approved Amount",
    render: (item) => {
      return (
        <p>
          {formatMoney(
            item?.approved_allocation?.aggregate?.sum?.wallet_balance
          )}
        </p>
      );
    },
  },
];

export const merchant_columns = [
  {
    title: "Merchant name",
    key: "name",
    dataIndex: "name",
  },
  {
    title: "Transactions",
    render: (item) => {
      return <p>{item?.merchants_sales_aggregate?.aggregate?.count}</p>;
    },
  },
  {
    title: "Total Billings ",
    render: (item) => {
      return (
        <p>{item?.merchants_sales_aggregate?.aggregate?.sum?.amount ?? 0}</p>
      );
    },
  },
];

export const cpg_columns = [
  {
    title: "First name",
    key: "first_name",
    dataIndex: "first_name",
  },
  {
    title: "Last name",
    key: "last_name",
    dataIndex: "last_name",
  },
  {
    title: "Transactions",
    render: (item) => {
      return <p>{item?.merchants_sales_aggregate?.aggregate?.count}</p>;
    },
  },
  {
    title: "Balance",
    render: (item) => {
      return (
        <p>
          {formatMoney(
            item?.merchants_sales_aggregate?.aggregate?.sum?.amount ?? 0
          )}
        </p>
      );
    },
  },
];

export const summary_columns = [
  {
    title: "Assisting Office",
    key: "group",
    dataIndex: "group",
  },
  {
    title: "Category A",
    render: (item) => <p>{item?.cat_a_count?.aggregate?.count}</p>,
  },
  {
    title: "Category B",
    render: (item) => <p>{item?.cat_b_count?.aggregate?.count}</p>,
  },
  {
    title: "Category C",
    render: (item) => <p>{item?.cat_c_count?.aggregate?.count}</p>,
  },
  {
    title: "Approved",
    render: (item) => <p>{item?.total_approved?.aggregate?.count}</p>,
  },
  {
    title: "Applicants",
    render: (item) => (
      <p>
        {item?.cat_a_count?.aggregate?.count +
          item?.cat_b_count?.aggregate?.count +
          item?.cat_c_count?.aggregate?.count}
      </p>
    ),
  },
  {
    title: "Amount",
    render: (item) => (
      <p>
        {formatMoney(
          item?.cat_a_pending?.aggregate?.count * 50000 +
            item?.cat_b_pending?.aggregate?.count * 20000 +
            item?.cat_c_pending?.aggregate?.count * 10000 +
            item?.cat_a_screened?.aggregate?.count * 50000 +
            item?.cat_b_screened?.aggregate?.count * 20000 +
            item?.cat_c_screened?.aggregate?.count * 10000 +
            item?.cat_a_accept?.aggregate?.count * 50000 +
            item?.cat_b_accept?.aggregate?.count * 20000 +
            item?.cat_c_accept?.aggregate?.count * 10000 +
            item?.cat_a_approved?.aggregate?.count * 50000 +
            item?.cat_b_approved?.aggregate?.count * 20000 +
            item?.cat_c_approved?.aggregate?.count * 10000
        )}
      </p>
    ),
  },
  {
    title: "Percent / Allocated",
    render: (item) => {
      let total_allocation;
      total_allocation = item?.username === "bm.vgo" ? 15000000 : 5000000;
      return (
        <p>
          {((item?.cat_a_pending?.aggregate?.count * 50000 +
              item?.cat_b_pending?.aggregate?.count * 20000 +
              item?.cat_c_pending?.aggregate?.count * 10000 +
              item?.cat_a_screened?.aggregate?.count * 50000 +
              item?.cat_b_screened?.aggregate?.count * 20000 +
              item?.cat_c_screened?.aggregate?.count * 10000 +
              item?.cat_a_accept?.aggregate?.count * 50000 +
              item?.cat_b_accept?.aggregate?.count * 20000 +
              item?.cat_c_accept?.aggregate?.count * 10000 +
              item?.cat_a_approved?.aggregate?.count * 50000 +
              item?.cat_b_approved?.aggregate?.count * 20000 +
              item?.cat_c_approved?.aggregate?.count * 10000) /
              total_allocation * 100).toFixed(2)}%
        </p>
      );
    },
  },
  {
    title: "Amount Spent",
    render: (item) => {
     return <p>{formatMoney(
        _.sumBy(item?.group_aggregate?.nodes, 
          function(o){
            return o?.merchants_sales_aggregate?.aggregate?.sum?.amount
          }
        )??0
      )}</p>
    }
  },
  {
    title: "Approved Users Wallet balance",
    render: (item) => (
      <p>
        {formatMoney(
                _.sumBy(item?.balance,(o)=>{
                  let balance = o.wallet_balance
                  let category = o.category
                  if (balance === null) {
                    switch (category) {
                      case 'a':
                        balance = 50000
                        break;
                      case 'b':
                        balance = 20000
                        break;
                      default:
                        balance = 10000
                        break;
                    }
                    return balance
                  } else {
                    return balance
                  }
                })??0
              )}
      </p>
    )
  }
];
