import React, { useMemo } from "react";
import { PageContext } from "../../lib/context";
import DashboardViews from "./view";
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import { CPGUSERS } from "../../lib/query";
import { PORTAL_USER } from "../../lib/subscriptions.js";
import { useState } from "react";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { notification, Form } from "antd";
import { UPDATE_USER_INFO } from "../../lib/mutations";

const CPGUsersController = (props) => {
  const [registrations, setRegistrations] = useState(null);
  const [group, setGroup] = useState("");
  const [visible, setVisible] = useState(false);
  const [csvCpgData, setCsvCpgData] = useState([
    [
      "Name",
      "Email",
      "Mobile number",
      "Gender",
      "Address",
      "Category",
      "Business name",
      "Status",
    ],
  ]);

  const [form] = Form.useForm();

  const { data: portalUser } = useSubscription(PORTAL_USER);

  const [updateUserInfo, loading: updatingUserInfo] = useMutation(
    UPDATE_USER_INFO
  );

  useEffect(() => {
    if (portalUser) {
      console.log("portalUser", portalUser);
      setGroup(portalUser?.portal_users?.[0]?.group);
    }
  }, [portalUser]);

  const {
    data: user_registrations,
    loading: loading_user_registrations,
  } = useQuery(CPGUSERS, {
    variables: {
      group,
    },
  });

  const getCategory = (category) => {
    return category === "a"
      ? "NEGOSYONG PADAYONON CATEGORY"
      : category === "b"
      ? "PRODUKTONG SUGBOANON CATEGORY"
      : "MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY";
  };

  useEffect(() => {
    if (user_registrations) {
      console.log("user_registration", user_registrations);
      setRegistrations(user_registrations?.users);
    }
  }, [user_registrations]);

  useMemo(() => {
    if (user_registrations) {
      if (user_registrations?.users?.length) {
        const csvItems = [];
        if (!loading_user_registrations) {
          const cpg_length = user_registrations?.users?.length;
          let temp_items_length = 0;
          const columns = [...csvCpgData[0]];
          for (let i = 0; i < cpg_length; i++) {
            const item = user_registrations?.users?.[i];
            const items_length = item?.user_items?.length;
            let length;
            if (items_length > temp_items_length)
              length = items_length - temp_items_length;
            for (let a = 0; a < length; a++) {
              columns.push(`Item no. ${temp_items_length + a + 1}`);
              if (a === items_length - 1) temp_items_length = items_length;
            }
            if (i === cpg_length - 1) {
              csvItems.push(columns);
              console.log("colums", csvItems);
              for (let j = 0; j < cpg_length; j++) {
                const item = user_registrations?.users?.[j];
                const address = `House no. ${item?.house_no}, Floor #: ${item?.floor_no}, Building Apt/Name: ${item?.building_apartment_name}
                Street name: ${item?.street_name}, Sitio or Purok: ${item?.sitio_or_purok}, 
                Subdivision: ${item?.subdivision}, Barangay: ${item?.barangay}, City/Municipality: ${item?.city_municipality}`;
                const data = [
                  item?.first_name +
                    " " +
                    item?.last_name +
                    " " +
                    item?.middle_name +
                    " " +
                    item?.extension,
                  item?.email,
                  item?.mobile_number,
                  item?.gender,
                  address,
                  getCategory(item?.category),
                  item?.new_business_name,
                  item?.status,
                ];
                const items_length = item?.user_items?.length;
                if (items_length > 0) {
                  for (let a = 0; a < items_length; a++) {
                    data.push(
                      `Item: ${item?.user_items?.[a]?.item?.replace(
                        /,|'|"/g,
                        ""
                      )} \nQuantity: ${
                        item?.user_items?.[a]?.quantity ?? 0
                      } \nUnit Price${
                        item?.user_items?.[a]?.unit_price ?? 0
                      } \nCost: ${
                        item?.user_items?.[a]?.cost ?? 0
                      } \nSupplier: ${
                        item?.user_items?.[
                          a
                        ]?.suggested_supplier_vendor?.replace(/,|'|"/g, "") ??
                        "N/A"
                      }`
                    );
                    if (a === items_length - 1) csvItems.push(data);
                  }
                } else {
                  csvItems.push(data);
                }
                if (j === cpg_length - 1) {
                  setCsvCpgData([...csvItems]);
                }
              }
            }
          }
        }
      }
    }
  }, [user_registrations, setCsvCpgData]);

  const onOfficeChange = (office) => {
    setGroup(office + "%");
  };

  const handleSave = async (data) => {
    const result = await updateUserInfo({
      variables: {
        id: data?.id ?? 0,
        data: {
          ...data,
        },
      },
    });

    if (result?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Successfully updated info",
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      notification.error({
        message: "Failed to update user info",
      });
    }
  };

  const onSelect = (data) => {
    form.setFieldsValue({
      ...data,
    });
    setVisible(true);
  };

  const pageValues = {
    registrations,
    onOfficeChange,
    csvCpgData,
    loading_user_registrations,
    form,
    visible,
    setVisible,
    handleSave,
    onSelect,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardViews />
    </PageContext.Provider>
  );
};

export default CPGUsersController;
