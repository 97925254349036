import React from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { columns } from "./components/table-columns";
import { Offices } from "../../lib/offices";
import {
  Form,
  Modal,
  Button,
  Input,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import { useState } from "react";
import { useRef } from "react";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";

const DashboardViews = (props) => {
  const {
    registrations,
    onOfficeChange,
    csvCpgData,
    loading_user_registrations,
    visible,
    setVisible,
    setUserId,
    handleSave,
    form,
    onSelect,
  } = useContext(PageContext);
  const { IS_ADMIN } = useContext(AppContext);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      return dataIndex === "name"
        ? (record["first_name"] + " " + record["last_name"])
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? text : text),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  return (
    <div className="mt-10">
      <p className="text-2xl">Imported Users</p>
      <div>
        {IS_ADMIN && (
          <Select className="w-2/5 my-3" onChange={onOfficeChange}>
            {Offices.map((office, key) => (
              <Select.Option value={office.OFFICE}>
                {office.OFFICE}
              </Select.Option>
            ))}
          </Select>
        )}
        <br />
        <br />
        <CSVLink data={csvCpgData}>
          <Button
            type="primary"
            className="mb-2"
            disabled={loading_user_registrations}
          >
            {loading_user_registrations ? <Spin /> : "Download CSV"}
          </Button>
        </CSVLink>
        <Table
          columns={[
            {
              title: "Name",
              key: "name",
              ...getColumnSearchProps("name"),
              render: (text) => (
                <span>
                  {text.first_name} {text.last_name} {text.middle_name}{" "}
                  {text.extension}
                </span>
              ),
            },
            {
              title: "Barangay",
              dataIndex: "barangay",
              key: "barangay",
              ...getColumnSearchProps("barangay"),
            },
            {
              title: "City/Municipality",
              dataIndex: "city_municipality",
              key: "city_municipality",
              ...getColumnSearchProps("city_municipality"),
            },
            {
              title: "Gender",
              dataIndex: "gender",
              key: "gender",
            },
            {
              title: "Wallet balance",
              dataIndex: "wallet_balance",
              key: "wallet_balance",
            },
            {
              title: "Action",
              key: "action",
              render: (item) => <EditOutlined onClick={() => onSelect(item)} />,
            },
          ]}
          dataSource={registrations}
        />

        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          okButtonProps={{ style: { display: "none" } }}
          title="Edit User Details"
        >
          <Form form={form} onFinish={handleSave}>
            <Form.Item name="id" className="hidden">
              <Input placeholder="id" />
            </Form.Item>

            <Form.Item name="first_name">
              <Input placeholder="First name" />
            </Form.Item>

            <Form.Item name="last_name">
              <Input placeholder="Last name" />
            </Form.Item>

            <Form.Item name="middle_name">
              <Input placeholder="Middle name" />
            </Form.Item>

            <Form.Item name="extension">
              <Input placeholder="Extension example: Jr., Senior, III" />
            </Form.Item>
            <Form.Item name="barangay">
              <Input placeholder="Barangay" />
            </Form.Item>

            <Form.Item name="city_municipality">
              <Input placeholder="City/Municipality" />
            </Form.Item>

            <Form.Item name="gender">
              <Select>
                <Select.Option value="F">Female</Select.Option>
                <Select.Option value="Male">Male</Select.Option>
              </Select>
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default DashboardViews;
