import React, { useContext } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  Checkbox,
  Button,
  Space,
  Table,
  Upload,
} from "antd";
import {
  UserOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { PageContext } from "../../../lib/context";
import { item_columns_a, certificates_column } from "../table-columns";
import Modal from "antd/lib/modal/Modal";
import { Offices } from "./offices";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { useMemo } from "react";
import { getSignedImageUrl } from "../../../lib/util";
class ComponentToPrint extends React.Component {
  render() {
    const item = this.props.item;
    const category = this.props.category;
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: "flex",
          width: 1150,
          textAlign: "left",
          alignItems: "center",
        }}
      >
        <div>
          <img src={item?.user_picture} className="h-32 w-32 mb-5" />
          <p>
            Name: {item?.first_name?.toUpperCase()}{" "}
            {item?.last_name?.toUpperCase()}
          </p>
          <p>Number: {item?.mobile_number}</p>
          <p>
            House #: {item?.house_no}, Floor #: {item?.floor_no}, Building
            Apt/Name: {item?.building_apartment_name}
          </p>
          <p>
            Street name: {item?.street_name}, Sitio or Purok:{" "}
            {item?.sitio_or_purot}, Subdivision: {item?.subdivision}, Barangay:{" "}
            {item?.barangay}, City/Municipality: {item?.city_municipality}
          </p>
          <p>Category: {category}</p>
          <p>Business: {item?.new_business_name}</p>
          <br />
          <p>Need List:</p>
          {item.user_items.map((item, key) => (
            <p>
              Item: {item?.item} - Unit price:{" "}
              {new Intl.NumberFormat("en-us", {
                currency: "php",
                style: "currency",
              }).format(item?.unit_price)}{" "}
            </p>
          ))}
        </div>
      </div>
    );
  }
}

const FormA = (props) => {
  const {
    form,
    updating_category,
    updating_user,
    updating_user_info,
    items,
    registrations,
    renderButtons,
    handleSave,
    handleSetToPending,
    getCategory,
    handleSetToDenied,
    handleUserImageChange,
    updatingPicture,
    picture,
    user_id_picture,
  } = useContext(PageContext);

  const componentRef = useRef();

  const registrationCertificates = [
    "DTI Business name permit",
    "Mayors Business permit",
    "Barangay Business permit",
    "BIR Registration",
    "Barangay Micro Business Enterprise (BMBE) Registration",
  ];

  const validIDs = [
    "UMID",
    "TIN ID",
    "Drivers License",
    "Postal ID",
    "Voters ID",
    "Passport",
    "Senior Citizen ID",
    "School ID (if student)",
  ];

  const btnDisabled = () => {
    if (
      registrations?.status === "approved" ||
      registrations?.status === "denied"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const required = (name) => {
    return [
      {
        required: true,
        message: `${name} is required`,
      },
    ];
  };

  const getNewStatus = (currentStatus) => {
    switch (currentStatus) {
      case "pending":
        return "accept";
      case "accept":
        return "screened";
      case "screened":
        return "approved";
    }
  };

  return (
    <div>
      <Form layout="vertical" form={form} onFinish={handleSave}>
        <p className="font-bold">Form CATEGORY A</p>
        <p className="font-bold">Personal information:</p>
        <div className="flex justify-center items-center">
          <img src={picture} className="h-32 w-32 mb-5 rounded-full" />
        </div>
        <div className="mb-5 mt-5">
          <Upload beforeUpload={handleUserImageChange} accept=".png,.jpg,.jpeg">
            <Button icon={<UploadOutlined />}>Upload Selfie Picture</Button>
          </Upload>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            required
            rules={required("Office")}
            label="Choose Office"
            name="assigned_group"
            className="w-full mr-5"
          >
            <Select>
              {Offices.map((item) => (
                <Select.Option value={item?.OFFICE}>
                  {item?.OFFICE}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            required
            rules={required("First name")}
            label="First name"
            name="first_name"
            className="w-full mr-5"
          >
            <Input placeholder="Juan" prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("Last name")}
            label="Last name"
            name="last_name"
            className="w-full mr-5"
          >
            <Input placeholder="Dela Cruz" prefix={<UserOutlined />} />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Gender")}
            label="Gender"
            className="w-full mr-5"
            name="gender"
          >
            <Select>
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            required
            rules={required("Civil status")}
            label="Civil Status"
            name="civil_status"
            className="w-full mr-5"
          >
            <Select>
              <Select.Option value="single">Single</Select.Option>
              <Select.Option value="married">Married</Select.Option>
              <Select.Option value="separated">Separated</Select.Option>
              <Select.Option value="widowed">Widowed</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Birth date")}
            label="Date of birth"
            name="birth_date"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Place of birth")}
            label="Place of birth"
            name="place_of_birth"
            className="w-full mr-5"
          >
            <Input placeholder="Location" prefix={<UserOutlined />} />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Family count")}
            label="Family members count"
            name="family_count"
            className="w-full mr-5"
          >
            <InputNumber className="w-full" placeholder="ex. 3" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Total income")}
            label="Total income per month"
            name="monthly_income"
            className="w-full mr-5"
          >
            <InputNumber
              placeholder="ex. 30000"
              className="w-full"
              prefix={<UserOutlined />}
            />
          </Form.Item>
        </div>
        <p className="font-bold">Complete address:</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("House no")}
            label="House no. or Lot/blk/Phase"
            name="house_no"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 0190" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("Floor no.")}
            label="Floor no. "
            name="floor_no"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 4" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("Building name is required")}
            label="Building Apartment/name"
            name="building_apartment_name"
            className="w-full mr-5"
          >
            <Input
              placeholder="ex. Deca Homes"
              prefix={<InfoCircleOutlined />}
            />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Street name")}
            label="Street name"
            name="street_name"
            className="w-full mr-5"
          >
            <Input
              placeholder="ex. Bonifacio street"
              prefix={<InfoCircleOutlined />}
            />
          </Form.Item>
          <Form.Item
            required
            rules={required("Sitio or purok")}
            label="Sitio or Purok"
            name="sitio_or_purok"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Mayahay" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            label="Subdivision"
            name="subdivision"
            className="w-full mr-5"
          >
            <Input
              placeholder="ex. Maria luisa"
              prefix={<InfoCircleOutlined />}
            />
          </Form.Item>
          <Form.Item
            required
            rules={required("Barangay")}
            label="Barangay"
            name="barangay"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Lahug" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("City/Municipality")}
            label="City/Municipality"
            name="city_municipality"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Cebu" prefix={<InfoCircleOutlined />} />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Your contact information:</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Cellphone number")}
            label="Cellphone number"
            name="mobile_number"
            className="w-full mr-5"
          >
            <Input placeholder="639XXXXXXXXX" className="w-full" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Email address")}
            label="Email address"
            name="email"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Landline number"
            name="landline_number"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="FB messenger"
            name="fb_messenger"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Information about your spouse:</p>
        <div className="flex flex-row">
          <Form.Item
            label="Name of spouse"
            name="name_of_spouse"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Spouse birth date"
            name="spouse_birth_date"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            label="Contact number"
            name="spouse_contact_number"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Details about your business:</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Name of business?"
            name="new_business_name"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Sari-sari store" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Industry?"
            name="industry"
            className="w-full mr-5"
          >
            <Select placeholder="Trading, Services, Agri-based, and Manufacturing">
              <Select.Option value="Trading">Trading</Select.Option>
              <Select.Option value="Services">Services</Select.Option>
              <Select.Option value="Agri-based">Agri-based</Select.Option>
              <Select.Option value="Manufacturing">Manufacturing</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="What kind of business?"
            name="business_kind"
            className="w-full mr-5"
          >
            <Input placeholder="Business" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Business Mobile?"
            name="business_mobile"
            className="w-full mr-5"
          >
            <Input placeholder="Mobile Number" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Business Landline?"
            name="business_landline"
            className="w-full mr-5"
          >
            <Input placeholder="Landline" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Business Website/Social Media"
            name="business_website_social"
            className="w-full mr-5"
          >
            <Input placeholder="Website/Social Media" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Currently have business?"
            name="has_business"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Is your business registered?"
            name="is_business_registered"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            required
            rules={required("Estimated Daily Sales")}
            label="Estimated Daily Sales"
            name="estimated_daily_sales"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 500" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="How many Month/s or Year/s is your business running."
            name="business_months_years"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 3 months, 1 year, 5 years" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Address")}
            label="Business address"
            name="business_address"
            className="w-full mr-5"
          >
            <Input placeholder="Complete address of your business" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Do you own the lot where your business resides?"
            name="is_lot_owned"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            required
            rules={required("Number of employees")}
            label="Number of employees"
            name="number_of_employees"
            className="w-full mr-5"
          >
            <InputNumber placeholder="ex. 5" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="(If renting) How much is your rent per month?"
            name="business_rent"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 5000" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Owner of the place"
            name="business_rent_owner"
            className="w-full mr-5"
          >
            <Input placeholder="Name of owner" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Address of the rented place?"
            name="business_rent_address"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Lahug Cebu City" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Contact number of the owner"
            name="business_rent_owner_number"
            className="w-full mr-5"
          >
            <Input placeholder="Contact number of owner" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="If registered, what certificates do you have now? (check which applies)"
            name="business_certificates"
            className="w-full mr-5"
          >
            <Checkbox.Group options={registrationCertificates} />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Have you already received any financial support to improve your business?"
            name="has_received_financial_support"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Date received the financial support?"
            name="receive_date"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            label="Received Amount"
            name="receive_amount"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 5000" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Agency who gave you the financial support?"
            name="receive_agency"
            className="w-full mr-5"
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="What did you do to the financial support you received?"
            name="receive_usage"
            className="w-full mr-5"
          >
            <Input.TextArea placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">
          Financial information about your business
        </p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Purpose of your application?"
            name="application_purpose"
            className="w-full mr-5"
          >
            <Select>
              <Select.Option value="additional">
                Additional investment
              </Select.Option>
              <Select.Option value="new">Create a new business</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Income</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Total income in a month?"
            name="total_monthly_income"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Profit</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Total profit ?"
            name="total_monthly_product_sales"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Total Net Income in a month?"
            name="net_monthly_income"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Total worth of your materials and equipments for your business?"
            name="total_net_worth"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Profit</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Monthly cost on rent?"
            name="monthly_rent_cost"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Other expenses?"
            name="other_expenses"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">
          Other expenses not related to your business
        </p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Other costs, debt from other businesses?"
            name="other_expense_debt"
            className="w-full mr-5"
          >
            <Input placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">
          List all of the items you own for your business.
        </p>
        <Table dataSource={items} columns={item_columns_a} />
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="What are your experiences, knowledge, trainings and skills that would help you on your business?"
            name="experiences"
            className="w-full mr-5"
          >
            <Input.TextArea placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Valid ID information:</p>
        <div className="flex justify-center items-center">
          <img src={user_id_picture} className="h-32 w-32 mb-5 rounded-lg" />
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Do you have a valid ID?"
            name="has_valid_id"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Valid ID")}
            label="What kind of valid ID do you have?"
            name="id_type"
            className="w-full mr-5"
          >
            <Select placeholder="Select ID">
              {validIDs.map((id, key) => (
                <Select.Option value={id}>{id}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Id number")}
            label="ID number?"
            name="id_number"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="ID date expiry?"
            name="id_expiry"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Agency issuer")}
            label="Agency ID was issued?"
            name="id_issuer"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p
          className={`text-2xl font-bold ${
            registrations?.status === "approved"
              ? "text-green-400"
              : registrations?.status === "pending"
              ? "text-blue-400"
              : "text-red-400"
          }`}
        >
          {registrations?.status === "approved" && "APPROVED"}
          {registrations?.status === "pending" && "PENDING"}
          {registrations?.status === "denied" && "DENIED"}
          {registrations?.status === "accept" && "ACCEPTED"}
        </p>
        {renderButtons()}
        <br />
        <br />
        <Button
          disabled={
            updating_user ||
            updating_user_info ||
            updating_category ||
            updatingPicture
          }
          onClick={() => form.submit()}
        >
          Save
        </Button>
        <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <ComponentToPrint
            ref={componentRef}
            item={registrations}
            category={getCategory(registrations.category)}
          />
        </div>
        {registrations?.status === "denied" && (
          <>
            <br />
            <br />
            <Button type="primary" onClick={handleSetToPending}>
              Set to pending
            </Button>
          </>
        )}
        {registrations?.status === "approved" && (
          <>
            <br />
            <br />
            <Button type="primary" onClick={handleSetToDenied}>
              Set to denied
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default FormA;
