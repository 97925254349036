import { useQuery } from "@apollo/react-hooks";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import GuestLayout from "../layout/guest";

export const AuthGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem(process.env.REACT_APP_LS_TOKEN) ? (
          <Component {...props} />
        ) : localStorage.getItem("is_admin") ? (
          <Redirect to={"/admin-reports"} />
        ) : (
          <Redirect to={"/dashboard"} />
        )
      }
    />
  );
};

export const DashGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem(process.env.REACT_APP_LS_TOKEN) ? (
          <GuestLayout>
            <Component {...props} />
          </GuestLayout>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
