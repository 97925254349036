import React, { useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import {
  UPSERT_USERS,
  UPSERT_USER_BUSINESS_CERTIFICATES,
  UPSERT_USER_ITEMS,
  LOGIN,
} from "../../lib/mutations";
import { useMutation } from "@apollo/react-hooks";
import jwt_decode from "jwt-decode";

const Registration = (props) => {
  const [form] = Form.useForm();

  const [login, { loading }] = useMutation(LOGIN);

  const handleFinishForm = async (data) => {
    const res = await login({
      variables: {
        object: {
          ...data,
          type: "portal",
        },
      },
    });
    const login_data = res?.data?.portal_login;
    if (login_data?.code == 200) {
      const decode = jwt_decode(login_data?.token);
      console.log("login data ", decode);
      if (
        decode["https://hasura.io/jwt/claims"]["x-hasura-default-role"] ==
        "encoder"
      ) {
        window.location.href = "/import";
      } else {
        window.location.href = "/dashboard";
      }
      localStorage.setItem(process.env.REACT_APP_LS_TOKEN, login_data?.token);
      /**      data?.email === "admin-user"
        ? (window.location.href = "/admin-reports")
        : data?.email === "encoder"
        ? (window.location.href = "/import")
        : (window.location.href = "/dashboard");
      data?.email === "admin-user"
        ? localStorage.setItem("is_admin", true)
        : localStorage.removeItem("is_admin"); **/
    } else {
      notification.error({
        message: "Login failed",
        description: "Incorrect username or password",
      });
    }
  };

  const pageValues = {
    form,
    handleFinishForm,
    loading,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <RegistrationView />
    </PageContext.Provider>
  );
};

export default Registration;
