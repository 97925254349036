import React, { useState } from "react";
import { Layout, Dropdown, Menu, Spin, SId } from "antd";
import {
  DesktopOutlined,
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  PrinterOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
import { useSubscription, useQuery } from "@apollo/react-hooks";
import { USER_INFO } from "../lib/subscriptions";
import { useEffect } from "react";
import { formatMoney } from "../lib/util";
import { useContext } from "react";
import { AppContext } from "../lib/context";
import { USER_DATA } from "../lib/query";

const GuestLayout = ({ children }) => {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);
  const [userData, setUserData] = useState(null);

  const { SET_USER_INFO, USER_INFO: INFO, IS_ADMIN, SET_IS_ADMIN } = useContext(
    AppContext
  );

  const { data, loading } = useSubscription(USER_INFO);

  const history = useHistory();

  const handleLink = (path) => {
    history.push(path);
  };

  const { data: user_data, loading: user_loading } = useQuery(USER_DATA);

  useEffect(() => {
    if (data) {
      setUserInfo(data?.users?.[0]);
      SET_USER_INFO(data?.users?.[0]);
      localStorage.setItem("userInfo", JSON.stringify(data?.users?.[0]));
    }
  }, [data]);

  useEffect(() => {
    if (user_data) {
      if (user_data?.portal_users?.length > 1) {
        setUserData({
          username: "admin-user",
          permission: "admin",
        });
        localStorage.setItem(
          "portal_user_info",
          JSON.stringify(user_data?.portal_users?.[0])
        );
        SET_IS_ADMIN(true);
      } else {
        setUserData(user_data?.portal_users?.[0]);
        localStorage.setItem(
          "portal_user_info",
          JSON.stringify(user_data?.portal_users?.[0])
        );
      }
    }
  }, [user_data]);

  const isCurrentPath = (path) => {
    if (path === location.pathname) return "text-yellow-400";
    else return "text-white";
  };

  const handleLogout = () => {
    localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
    window.location.href = "/";
  };

  const userMenu = (
    <Menu>
      <Menu.Item>
        <a rel="noopener noreferrer" onClick={handleLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const getSelectedKey = () => {
    switch (location.pathname) {
      case "/approved":
        return "2";
      case "/denied":
        return "3";
      case "/accepted":
        return "4";
      case "/screened":
        return "6";
      case "/pending":
        return "1";
      case "/dashboard":
        return "8";
      case "/print":
        return "9";
      case "/print-bulk":
        return "7";
      case "/import":
        return "5";
    }
  };

  return (
    <div className="flex flex-col h-auto">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider theme="light" collapsible>
          <div className="logo flex flex-col items-center justify-center">
            <img
              src={require("../assets/cebu-province-logo.jpg")}
              style={{ height: 100 }}
            />
            <p className="text-base font-bold">CPG Portal</p>
          </div>
          <Menu theme="light" selectedKeys={getSelectedKey()} mode="inline">
            {userData?.permission !== "encoder" && (
              <Menu.Item
                key="8"
                icon={<DashboardOutlined />}
                onClick={() => (window.location.href = "/dashboard")}
              >
                Dashboard
              </Menu.Item>
            )}
            {(userData?.permission === "admin" ||
              userData?.permission === "encoder") && (
              <Menu.Item
                key="9"
                icon={<PrinterOutlined />}
                onClick={() => (window.location.href = "/print")}
              >
                Print
              </Menu.Item>
            )}
            {userData?.permission === "admin" && (
              <Menu.Item
                key="7"
                icon={<PrinterOutlined />}
                onClick={() => (window.location.href = "/print-bulk")}
              >
                Bulk Print
              </Menu.Item>
            )}

            {(userData?.permission === "encoder" ||
              userData?.permission === "admin") && (
              <Menu.Item
                key="5"
                icon={<UsergroupAddOutlined />}
                onClick={() => (window.location.href = "/import")}
              >
                Import
              </Menu.Item>
            )}
            {(userData?.permission === "bm" ||
              userData?.permission === "admin") && (
              <Menu.Item
                key="1"
                icon={<UsergroupAddOutlined />}
                onClick={() => (window.location.href = "/pending")}
              >
                Pending
              </Menu.Item>
            )}
            {userData?.permission != "encoder" && (
              <Menu.Item
                key="2"
                icon={<UsergroupAddOutlined />}
                onClick={() => (window.location.href = "/approved")}
              >
                Approved
              </Menu.Item>
            )}
            {userData?.permission == "encoder" && (
              <Menu.Item
                key="2"
                icon={<UsergroupAddOutlined />}
                onClick={() => (window.location.href = "/cpgusers")}
              >
                CPG Users
              </Menu.Item>
            )}

            {userData?.permission != "encoder" && (
              <Menu.Item
                key="3"
                icon={<UsergroupAddOutlined />}
                onClick={() => (window.location.href = "/denied")}
              >
                Denied
              </Menu.Item>
            )}
            
            {userData?.permission === "bm" && (
              <Menu.Item
                icon={<UsergroupAddOutlined />}
                onClick={() =>
                  window.open(
                    "https://sn2.cpg.wepay.ph?encode_group=" + userData?.group,
                    "_blank"
                  )
                }
              >
                Encode Applications
              </Menu.Item>
            )}
            <Menu.Item
              key="0"
              icon={<LogoutOutlined />}
              onClick={() => {
                localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
                setTimeout(() => {
                  window.location.href = "/";
                }, 1000);
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
          <div className="flex flex-col mt-5 justify-center items-center text-xs">
            <p>Username: {userData?.username}</p>
            <p>District: {userData?.group}</p>
          </div>
          <div className="flex flex-row mt-20 justify-center items-center text-xs">
            <img
              src={require("../assets/wepay-square.png")}
              style={{ height: 20, objectFit: "contain" }}
            />
            <p>Powered by WePay</p>
          </div>
        </Sider>
        <Layout className="site-layout ">
          <Content style={{ margin: "0 16px" }} className="bg-white">
            {children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            CPG Portal @2020 WePay PH
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default GuestLayout;
