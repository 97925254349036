import gql from "graphql-tag";

export const LOGIN = gql`
  mutation MyMutation($object: LoginInput!) {
    portal_login(object: $object) {
      code
      message
      token
    }
  }
`;

export const UPSERT_USERS = gql`
  mutation MyMutation($object: [users_insert_input!]!) {
    insert_users(objects: $object) {
      returning {
        id
      }
    }
  }
`;

export const UPSERT_USER_ITEMS = gql`
  mutation MyMutation($object: [user_items_insert_input!]!) {
    insert_user_items(objects: $object) {
      affected_rows
    }
  }
`;

export const DELETE_USER_ITEMS = gql`
  mutation MyMutation($userid: uuid) {
    delete_user_items(where: {user_id: {_eq: $userid}}) {
      affected_rows
    }
  }
`;

export const UPSERT_USER_BUSINESS_CERTIFICATES = gql`
  mutation MyMutation($object: [user_business_certificates_insert_input!]!) {
    insert_user_business_certificates(objects: $object) {
      affected_rows
    }
  }
`;

export const UPDATE_USER = gql`
  mutation MyMutation($wallet_balance: Float, $status: String, $id: uuid) {
    update_users(
      where: { id: { _eq: $id } }
      _set: { wallet_balance: $wallet_balance, status: $status }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation MyMutation($category: String, $id: uuid) {
    update_users(where: { id: { _eq: $id } }, _set: { category: $category }) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation MyMutation($id: uuid, $data: users_set_input) {
    update_users(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;

export const INSERT_IMPORT_USERS = gql`
  mutation MyMutation($objects: [users_insert_input!]!) {
    insert_users(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation MyMutation($id: uuid, $status: String!) {
    update_users(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;


export const UPDATE_WALLET = gql `
mutation MyMutation($id: uuid) {
  update_users(where: {id: {_eq: $id}}, _set: {wallet_balance: 10000, activated: true}) {
    affected_rows
  }
}
`;