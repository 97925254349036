export const Offices = [
  {
    NAME: "Jovy Gerodias",
    OFFICE: "Vice Governor Office",
    "CONTACT #": "0926-084-6135",
    EMAIL: "jtgerodias@gmail.com",
  },
  {
    NAME: "Kathleen Daan",
    OFFICE: "SP Daan",
    EMAIL: "kathleen_daan@yahoo.com",
  },
  {
    NAME: "Germaine D. Esparol",
    OFFICE: "SP Bacaltos",
    "CONTACT #": "0917-712-4092",
    EMAIL: "spraulbacaltos@gmail.com",
  },
  {
    NAME: "Clinton Alido",
    OFFICE: "SP Salvador",
    "CONTACT #": "0905-522-1353",
    EMAIL: "spsalvador123@gmail.com",
  },
  {
    NAME: "Leonilo A. Birondo Jr.",
    OFFICE: "SP Galeos",
    "CONTACT #": "0927-206-1544",
    EMAIL: "spgaleos2nddistrict@gmail.com",
  },
  {
    NAME: "Jonathan Y. Pepito ",
    OFFICE: "SP Corominas-Toribio",
    "CONTACT #": "0942-493-6873",
    EMAIL: "spcorominastoribio@gmail.com",
  },
  {
    NAME: "Geraldine Yunsal",
    OFFICE: "SP Borgonia",
    "CONTACT #": "0917-713-0736",
    EMAIL: "boardmemberborgonia@gmail.com",
  },
  {
    NAME: "Sharrie Mae Legaspi",
    OFFICE: "SP Shimura",
    "CONTACT #": "0923-911-7088",
    EMAIL: "pb_sunjshimura@yahoo.com",
  },
  {
    NAME: "Arsehio Gonzalo Dulosa III",
    OFFICE: "SP Franco",
    "CONTACT #": "0917-889-2472",
    EMAIL: "spracifranco@gmail.com",
  },
  {
    NAME: "Antonio L. Denuyo Jr.",
    OFFICE: "SP Duterte",
    "CONTACT #": "0915-076-2591",
    EMAIL: "spduterte@gmail.com",
  },
  {
    OFFICE: "SP Magpale",
    EMAIL: "pbmagpale@gmail.com",
  },
  {
    NAME: "Jennifer Colimbo",
    OFFICE: "SP Ouano",
    "CONTACT #": "0916-475-5145",
    EMAIL: "tjoprovince@yahoo.com",
  },
  {
    NAME: "Dessa Canoy",
    OFFICE: "SP Soco",
    "CONTACT #": "0942-9600288",
    EMAIL: "dessacanoy@gmail.com",
  },
  {
    NAME: "Jose Balbona ",
    OFFICE: "SP Librando",
    "CONTACT #": "0917-721-0612",
    EMAIL: "spjeromelibrando@yahoo.com",
  },
  {
    NAME: "Marie Trixie Quirante",
    OFFICE: "SP Baricuatro",
    "CONTACT #": "0935-738-0138",
    EMAIL: "officeofbmbaricuatro@gmail.com",
  },
  {
    NAME: "Roudey Peter Rodriguez",
    OFFICE: "SP Martinez",
    "CONTACT #": "0947-899-8909",
    EMAIL: "honeydu30@gmail.com",
  },
  {
    NAME: "Pol S. Ochavillo",
    OFFICE: "SP Rubio",
    "CONTACT #": "0922-656-5467",
    EMAIL: "ppskcebu2019@gmail.com",
  },
  {
    NAME: "Jezel Cañete",
    OFFICE: "SP PCL Bacaltos",
    "CONTACT #": "0933-503-9485",
    EMAIL: "pclcebu2019@gmail.com",
  },
];
