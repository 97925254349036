import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, Table } from "antd";
import {
  bm_columns,
  cpg_columns,
  merchant_columns,
  summary_columns,
} from "./table-columns";
import { CSVLink, CSVDownload } from "react-csv";

const DashboardView = (props) => {
  const {
    data,
    loading,
    bm_data,
    bm_loading,
    m_data,
    m_loading,
    cpg_data,
    cpg_loading,
    allocations,
    bm_estimates,
    csvData,
    allocations_data,
    csvMData,
    csvCpgData,
    csvSummary,
  } = useContext(PageContext);

  if (loading)
    return (
      <div className="flex h-full">
        <Spin className="self-center" />
      </div>
    );

  return (
    <div className="flex flex-col h-auto items-center pt-32">
      <p className="text-2xl mb-10">Admin Reports</p>
      <div className="mt-10 w-4/5">
        <p>List of BM's</p>
        <CSVLink data={csvData}>
          <Button type="primary" disabled={bm_loading}>
            {bm_loading ? <Spin /> : "Download CSV"}
          </Button>
        </CSVLink>
        <Table
          className="mt-2"
          dataSource={bm_data?.portal_users ?? []}
          columns={bm_columns}
          scroll={{ x: 1500 }}
        />
      </div>
      <div className="mt-10 w-4/5">
        <p>Category Summary</p>
        <CSVLink data={csvSummary}>
          <Button type="primary" className="mb-2" disabled={cpg_loading}>
            {cpg_loading ? <Spin /> : "Download CSV"}
          </Button>
        </CSVLink>
        <Table
          dataSource={bm_data?.portal_users ?? []}
          columns={summary_columns}
        />
      </div>
      <div className="mt-10 w-3/5">
        <p>List of Merchants</p>
        <CSVLink data={csvMData}>
          <Button type="primary" className="mb-2" disabled={m_loading}>
            {m_loading ? <Spin /> : "Download CSV"}
          </Button>
        </CSVLink>
        <Table
          dataSource={m_data?.merchants ?? []}
          columns={merchant_columns}
        />
      </div>
      <div className="mt-10 w-3/5">
        <p>List of CPG USERS</p>
        <CSVLink data={csvCpgData}>
          <Button type="primary" className="mb-2" disabled={cpg_loading}>
            {cpg_loading ? <Spin /> : "Download CSV"}
          </Button>
        </CSVLink>
        <Table dataSource={cpg_data?.users ?? []} columns={cpg_columns} />
      </div>
    </div>
  );
};

export default DashboardView;
