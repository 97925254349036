import React, { useRef, useState } from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, AutoComplete, Select } from "antd";
import { useEffect } from "react";
import { useMemo } from "react";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import { Offices } from "../../lib/offices";

class ComponentToPrint extends React.Component {
	render() {
		return (
			<div
				className="text-gray-700"
				style={{
					padding: 10,
					display: "flex",
					flexDirection: "row",
					width: 1150,
					flexWrap: "wrap",
					textAlign: "left",
					alignItems: "center",
				}}
			>
				{this.props.selected.map((id, key) => (
					<div
						onClick={() => this.props.handleDelete(key)}
						style={{
							display: "flex",
							flexDirection: "row",
							marginTop:
								(key % 10 === 0 || (key % 10 === 1 && key > 10)) && key !== 0
									? 355
									: 15,
							// width: "50%",
						}}
					>
						<div
							style={{
								backgroundImage: `url(${require("../../assets/card-v2.jpg")})`,
								backgroundSize: "contain",
								backgroundRepeat: "no-repeat",
								height: 320,
								width: 550,
								marginRight: 2,
								objectFit: "fill",
								position: "relative",
							}}
						>
							{/* <div style={{
                  position: "absolute",
                  height: 71,
                  width: 70,
                  right: 58,
                  top: 4,
                }}> */}
							<div
								className="normal-qr"
							>
								<QRCode
									size={87}
									value={id?.id}
								//logoWidth={40}
								//logoHeight={40}
								//logoImage={require("../../assets/cebu-province-logo.jpg")}
								/>
							</div>

							{/* </div> */}
							<img
								src={id?.user_picture ? `${id?.user_picture}` : require("../../assets/empty-profile.PNG")}
								alt={"alt"}
								className="h-20 w-20"
								style={{
									objectFit: "cover",
									width: 108,
									height: 108,
									position: "absolute",
									left: 28,
									top: 179,
								}}
							/>
							<p
								style={{
									position: "relative",
									left: 200,
									top: 177,
									fontSize: 14,
								}}
							>{`${id?.first_name?.toUpperCase()} ${id?.last_name?.toUpperCase()}`}</p>
							<p
								style={{
									position: "relative",
									left: 247,
									top: 192,
									fontSize: 11,
								}}
							>{`${id?.id}`}</p>
							<p
								style={{
									position: "relative",
									left: 230,
									top: 208,
									fontSize: 11,
								}}
							>{`${id?.barangay?.toUpperCase()} ${id?.city_municipality?.toUpperCase()}`}</p>
							<p
								style={{
									position: "relative",
									left: 275,
									top: 226,
									fontSize: 11,
								}}
							>{`${Offices?.find(
								(el) => el.OFFICE === id?.assigned_group
							)?.OFFICE
								}`}</p>
						</div>
					</div>
				))}
			</div>
		);
	}
}
class ComponentToPrintPwd extends React.Component {

	render() {

		function isLastInGroupOf4(index, data) {
			const groupIndex = Math.floor(index / 4); // Calculate the group index
			const lastIndexInGroup = (groupIndex + 1) * 4 - 1; // Calculate the index of the last item in the group
			return index === lastIndexInGroup || index === data.length - 1;
		}


		return (
			<div
				className="text-gray-700"

				style={{
					padding: 10,
					display: "flex",
					flexDirection: "row",
					width: 1150,
					flexWrap: "wrap",
					textAlign: "left",
					alignItems: "center",
				}}
			>
				{this.props.selected.map((id, key) => (
					<div
						onClick={() => this.props.handleDelete(key)}
						style={{
							display: "flex",
							flexDirection: "row",
							//   marginTop:
							//     (key % 8 === 0 || (key % 8 === 1 && key > 8)) && key !== 0
							//       ? 150
							//       : 15,
							// width: "50%",
						}}
					>
						<div
							style={{
								backgroundImage: `url(${require("../../assets/pwd-final.jpg")})`,
								backgroundSize: "contain",
								backgroundRepeat: "no-repeat",
								height: 700,
								width: 548,
								marginTop: 20,
								marginRight: 15,
								objectFit: "fill",
								position: "relative",
								pageBreakAfter: isLastInGroupOf4(key, id) ? 'always' : 'auto',
							}}
						>
							<div
								className="pwd-qr"
							>
								<QRCode
									size={120}
									value={id?.id}
								//logoWidth={40}
								//logoHeight={40}
								//logoImage={require("../../assets/cebu-province-logo.jpg")}
								/></div>

							{/* <div style={{
                  position: "absolute",
                  height: 71,
                  width: 70,
                  right: 58,
                  top: 4,
                }}> */}

							{/* </div> */}
							<img
								src={id?.user_picture ? `${id?.user_picture}` : require("../../assets/empty-profile.PNG")}
								alt={"alt"}
								className="h-20 w-20"
								style={{
									objectFit: "cover",
									width: 176,
									height: 178,
									position: "absolute",
									left: 186,
									top: 132,
								}}
							/>
							<p
								style={{
									position: "relative",
									//right: 15,
									top: 317,
									fontSize: 20,
									textAlign: "center"
								}}
							>{`${id?.first_name?.toUpperCase()} ${id?.last_name?.toUpperCase()}`}</p>
							<p
								style={{
									position: "relative",
									//left: 15,
									//right: 15,
									top: 340,
									fontSize: 17,
									textAlign: "center"
								}}
							>{`${id?.category}`}</p>
							<p
								style={{
									position: "relative",
									//left: 96,
									//right: 15,
									top: 374,
									fontSize: 17,
									textAlign: "center"
								}}
							>{`${id?.barangay?.toUpperCase()} `}</p>
							<p
								style={{
									position: "relative",
									//left: 95,
									//right: 15,
									top: 405,
									fontSize: 17,
									textAlign: "center"
								}}
							>{`${id?.city_municipality?.toUpperCase()}`}</p>
						</div>
					</div>
				))}
			</div>
		);
	}
}
// class ComponentToPrint extends React.Component {
//   render() {
//     return (
//       <div
//         className="text-gray-700"
//         style={{
//           padding: 10,
//           display: "flex",
//           flexDirection: "row",
//           width: 1150,
//           flexWrap: "wrap",
//           textAlign: "left",
//           alignItems: "center",
//         }}
//       >
//         {this.props.selected.map((id, key) => (
//           <div
//             onClick={() => this.props.handleDelete(key)}
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               marginTop:
//                 (key % 10 === 0 || (key % 10 === 1 && key > 10)) && key !== 0
//                   ? 350
//                   : 10,
//               // width: "50%",
//             }}
//           >
//             <div
//               style={{
//                 backgroundImage: `url(${require("../../assets/card-v2.jpg")})`,
//                 backgroundSize: "contain",
//                 backgroundRepeat: "no-repeat",
//                 height: 320,
//                 width: 550,
//                 marginRight: 2,
//                 objectFit: "fill",
//                 position: "relative",
//               }}
//             >
//               {/* <div style={{
//                   position: "absolute",
//                   height: 71,
//                   width: 70,
//                   right: 58,
//                   top: 4,
//                 }}> */}
//               <QRCode
//                 value={id?.id}
//                 logoWidth={40}
//                 logoHeight={40}
//                 logoImage={require("../../assets/cebu-province-logo.jpg")}
//               />
//               {/* </div> */}

//               <p
//                 style={{
//                   position: "relative",
//                   left: 66,
//                   top: 16,
//                   fontSize: 15,
//                 }}
//               >{`${id?.first_name?.toUpperCase()} ${id?.last_name?.toUpperCase()}`}</p>
//               <p
//                 style={{
//                   position: "relative",
//                   left: 86,
//                   top: 39,
//                   fontSize: 15,
//                 }}
//               >{`${id?.barangay}`}</p>
//               <p
//                 style={{
//                   position: "relative",
//                   left: 93,
//                   top: 56,
//                   fontSize: 15,
//                 }}
//               >{`${id?.city_municipality?.toUpperCase()}`}</p>
//               <p
//                 style={{
//                   position: "relative",
//                   left: 95,
//                   top: 78,
//                   fontSize: 11,
//                 }}
//               >
//                 {id?.id}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }
// }

const DashboardView = (props) => {
	const {
		data,
		loading,
		onSelect,
		onSearch,
		selected,
		setSelected,
		options,
		value,
		handleDelete,
		assigned_group,
		filterInput,
		groupName,
		checker,
		setStatus,
		status,
		barangays,
		setBarangay,
		barangay,
		isPwd
	} = useContext(PageContext);

	const componentRef = useRef();
	return (
		<div className="flex flex-col h-auto pt-12">
			<p className="text-2xl mb-10">PRINT ID</p>
			<div>
				<Select
					className="py-4 mr-2"
					showSearch
					placeholder="Select Assisting Office"
					optionFilterProp="children"
					onChange={filterInput}
					value={groupName}
					// defaultValue="all"
					//onSearch={onSearch}
					filterOption={(input, option) =>
						option.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
				>
					{/* {Offices?.length > 0 &&
							Offices.map((item) => (
								<Select.Option
									value={item.OFFICE}
									key={item.OFFICE}
								>
									{item.OFFICE.toUpperCase()}
								</Select.Option>
							))} */}
					{isPwd ?
						<Select.Option
							value={"PGO PWD"}
							key={"PGO PWD"}
						>
							PGO PWD
						</Select.Option>
						:
						Offices?.length > 0 &&
						Offices.map((item) => (
							<Select.Option
								value={item.OFFICE}
								key={item.OFFICE}
							>
								{item.OFFICE.toUpperCase()}
							</Select.Option>
						))
					}
				</Select>
			</div>
			{/* <div>
        <Select
          className="py-4 w-1/5"
          showSearch
          placeholder="Select Municipality"
          optionFilterProp="children"
          onChange={filterInput}
          value={groupName}
          // defaultValue="all"
          //onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {assigned_group?.portal_users?.length > 0 &&
            assigned_group.portal_users.map((item) => (
              <Select.Option value={item.group} key={item.group}>
                {item.group.toUpperCase()}
              </Select.Option>
            ))}
        </Select>
      </div> */}
			{/* <div>
        <Select
          className="py-4 w-1/5"
          showSearch
          placeholder="Select Barangay"
          optionFilterProp="children"
          onChange={(barangay) => setBarangay(barangay)}
          value={barangay}
          // defaultValue="all"
          //onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {barangays?.users?.length > 0 &&
            barangays.users.map((item) => (
              <Select.Option value={item.barangay} key={item.barangay}>
                {item.barangay}
              </Select.Option>
            ))}
        </Select>
      </div> */}
			<div>
				<Select
					className="py-4 w-1/5"
					showSearch
					placeholder="Select Status"
					optionFilterProp="children"
					onChange={(status) => setStatus(status)}
					value={status}
					// defaultValue="all"
					//onSearch={onSearch}
					filterOption={(input, option) =>
						option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
					}
				>
					<Select.Option value={"approved"} key={"approved"}>
						Approved
					</Select.Option>
					<Select.Option value={"denied"} key={"denied"}>
						Denied
					</Select.Option>
				</Select>
			</div>

			<div className="mt-10">
				<p>Search user by First name or Last name</p>
				<AutoComplete
					options={options ? options : "No user found."}
					onSelect={onSelect}
					onSearch={onSearch}
					placeholder="Search by name"
					allowClear
					value={value}
					className="w-3/5"
				>
					<Input.Search placeholde="Search by name" />
				</AutoComplete>

				{selected?.length > 0 && (
					<div className="text-left ml-16 text-blue-400 font-bold">
						<ReactToPrint
							trigger={() => <button>Print</button>}
							content={() => componentRef.current}
						/>
						{groupName === "PGO PWD" ?
							<ComponentToPrintPwd
								ref={componentRef}
								selected={selected}
								handleDelete={handleDelete}
							/> :
							<ComponentToPrint
								ref={componentRef}
								selected={selected}
								handleDelete={handleDelete}
							/>
						}
					</div>
				)}
			</div>
		</div>
	);
};

export default DashboardView;
