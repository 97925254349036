import React, { useRef, useState } from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, AutoComplete, notification } from "antd";
import { useEffect } from "react";
import { useMemo } from "react";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import { Offices } from "../../lib/offices";
import CSVReader from "react-csv-reader";
import moment from "moment";

const ImportView = (props) => {
  const {
    setImportedData,
    submit,
    setCount,
    count,
    setDisable,
    disable,
    importedData,
    usergroup,
  } = useContext(PageContext);

  const csvData = (data) => {
    // console.log("testg",usergroup)
    let informationArray = [];
    let checker = false;
    setDisable(true);
    setImportedData([]);

    data.map((item, key) => {
      let info = {
        first_name: item?.[2],
        last_name: item?.[1],
        birth_date:
          moment(item?.[5]).format("YYYY-MM-DD") == "Invalid date"
            ? null
            : moment(item?.[5]).format("YYYY-MM-DD"),
        gender: item?.[6],
        middle_name: item?.[3],
        extension: item?.[4],
        barangay: item?.[7],
        city_municipality: item?.[8],
        category: item?.[10],
        status: "approved",
        assigned_group: usergroup,
        pin: Math.floor(100000 + Math.random() * 900000),
        wallet_balance:
          usergroup === "PGO PWD" ? 0 :
          item?.[10] === "TOTALLY DAMAGED" ||
          item?.[10] === "TOTALLY3" ||
          item?.[10] === "ADD TOTALLY DAMAGED"
            ? 8000
            : item?.[10] === "SEVERELY DAMAGED" || item?.[10] === "SEVERELY3"
            ? 5000
            : item?.[10] === "FISHERFOLK"
            ? 2000
            : 3000,
      };

      if (key != data.length ) {
      
        if (
          info?.first_name == "" ||
          info?.first_name == undefined ||
          info?.last_name == "" ||
          info?.last_name == undefined
        ) {
          checker = true;
        }
        informationArray.push(info);
      }
    });
    informationArray.splice(0, 1);
    //console.log(informationArray);
    if (checker == true) {
      notification.error({
        message: "Invalid Uploaded File",
        description:
          "Uploaded file has missing informations. Please make sure there are no blank fields for first names and last names.",
      });
      return;
    } else {
      setImportedData([...informationArray]);
      setDisable(false);
    }
  };

  const componentRef = useRef();
  return (
    <div className="flex flex-col h-auto pt-12">
      <div className="flex flex-col justify-center items-center">
        <p className="text-2xl mb-10">Import CSV</p>

        <CSVReader onFileLoaded={csvData} />

        <div className="pt-5">
          <Button
            disabled={importedData.length == 0}
            className="rounded-lg"
            onClick={submit}
          >
            Submit
          </Button>
        </div>
      </div>

      {/* <div className="mt-10">
        <p>Search user by First name or Last name</p>
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder="Search by name"
          allowClear
          value={value}
        >
          <Input.Search placeholde="Search by name" />
        </AutoComplete>

        {selected?.length > 0 && (
          <div className="text-left ml-16 text-blue-400 font-bold">
            <ReactToPrint
              trigger={() => <button>Print</button>}
              content={() => componentRef.current}
            />
            <ComponentToPrint
              ref={componentRef}
              selected={selected}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default ImportView;
