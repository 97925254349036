import React, { useCallback, useState, useEffect } from "react";
import { Input, Form, Button, notification, message } from "antd";
import DashboardView from "./view";
import { PageContext } from "../../lib/context";
import {
  ADMIN_BM_ESTIMATES,
  AGGREGATES,
  ALLOCATIONS,
  USERS_SEARCH,
  GET_ASSIGNED_GROUP,
  BARANGAYS,
} from "../../lib/query";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useMemo } from "react";
import _ from "lodash";
import { getSignedImageUrl } from "../../lib/util";
import CSVReader from "react-csv-reader";
import { UPSERT_USERS } from "../../lib/mutations";
import jwt_decode from "jwt-decode";

const PrintController = (props) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const searchTextDebouncer = useCallback(_.debounce(setSearch, 500), []);
  const [groupName, setGroupName] = useState();
  const [AllGroupUser, setAllGroupUser] = useState({});
  const [checker, setChecker] = useState(false);
  const [status, setStatus] = useState("approved");
  const [barangay, setBarangay] = useState("%%");
  const [isPwd,setIsPwd] = useState(false);

  const { data, loading } = useQuery(USERS_SEARCH, {
    variables: {
      search,
      assigned_group: groupName,
      status,
    },
  });

  // const { data:users, loading:loading_users } = useQuery(CPG_GROUP_USERS,{
  //   variables:{
  //     where: AllGroupUser
  //   }
  // });

  const { data: assigned_group } = useQuery(GET_ASSIGNED_GROUP);
  const { data: barangays } = useQuery(BARANGAYS);

  useMemo(() => {
    if (data && !loading) {
      //console.log("data", data);
      if (data?.users?.length) {
        const array = [];
        data.users.map((user, key) => {
          let temp = {
            label: `${user?.first_name?.toUpperCase()} ${user?.last_name?.toUpperCase()} ${
              user?.middle_name
            } ${user?.extension} - ${user?.barangay} - 
(${user?.assigned_group})
            `,
            value: JSON.stringify(user),
          };
          array.push(temp);
          if (key === data?.users?.length - 1) {
            console.log("arr", array);
            setOptions([...array]);
          }
        });
      } else {
        setOptions([]);
      }
    }
  }, [data]);

  useMemo(()=>{
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN)    
    const decode = jwt_decode(token);
    if (
      decode["https://hasura.io/jwt/claims"]["x-hasura-group"] ==
      "PGO PWD"
    ) {
      setIsPwd(true)
    }else{
      setIsPwd(false)
    }
  },[])
  const onSearch = (searchText) => {
    searchTextDebouncer("%" + searchText + "%");
    setValue(searchText);
  };

  const filterInput = (data) => {
    setGroupName(data);
  };

  const onSelect = async (data) => {
    let parsed = JSON.parse(data);
    // let user_picture = await getSignedImageUrl(parsed.user_picture);
    setSelected([...selected, { ...parsed}]);
    setValue(null);
  };

  const handleDelete = (key) => {
    setSelected(
      selected.filter(function (item, index) {
        return index !== key;
      })
    );
  };

  useEffect(() => {
    if (data?.length > 0) {
      setChecker(true);
    } else {
      setChecker(false);
    }
  }, [assigned_group]);

  const [state, setState] = useState([]);

  const [insertUser] = useMutation(UPSERT_USERS);

  const prepObject = async (data) => {
    const obj = [];
    data.map((item) => {
      if (item[2] != undefined) {
        let i = {
          first_name: item[2],
          last_name: item[1],
          current_location: item[5],
        };
        obj.push(i);
      }
    });
    console.log("data", obj);
    await insertUser({
      variables: {
        object: [...obj],
      },
    });
  };

  const pageValues = {
    data,
    loading,
    options,
    onSearch,
    selected,
    setSelected,
    onSelect,
    value,
    setValue,
    handleDelete,
    assigned_group,
    filterInput,
    groupName,
    checker,
    setStatus,
    setBarangay,
    barangay,
    barangays,
    isPwd
  };

  return (
    <PageContext.Provider value={pageValues}>
      <DashboardView />
    </PageContext.Provider>
  );
};

export default PrintController;
