import React, { useRef } from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import { Select } from "antd";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import { Offices } from "../../lib/offices";
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { fill } from "lodash";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";

class ComponentToPrint extends React.Component {

  getImage(picture) {
    const cld = new Cloudinary({
      cloud: {
        cloudName: 'smartparkme'
      }
    });
    const split = picture.split("/")

    return cld.image(split[split.length - 2] + "/" + split[split.length - 1]).resize(thumbnail().width(107).height(107))
  }

  render() {
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          width: 1150,
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        {this.props.selected.map((id, key) => (
          <div
            onClick={() => this.props.handleDelete(key)}
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop:
                (key % 10 === 0 || (key % 10 === 1 && key > 10)) && key !== 0
                  ? 355
                  : 15,
              // width: "50%",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${require("../../assets/card-v2.jpg")})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: 320,
                width: 550,
                marginRight: 2,
                objectFit: "fill",
                position: "relative",
              }}
            >
              {/* <div style={{
                  position: "absolute",
                  height: 71,
                  width: 70,
                  right: 58,
                  top: 4,
                }}> */}
              <QRCode
                value={id?.id}
                //logoWidth={50}
                //logoHeight={50}
                //logoImage={require("../../assets/cebu-province-logo.jpg")}
              />
              {/* </div> */}
              {id?.user_picture ? (
                <div style={{
                  objectFit: "cover",
                  width: 108,
                  height: 108,
                  position: "absolute",
                  left: 28,
                  top: 179,
                }}
                >

                  <AdvancedImage cldImg={this.getImage(id?.user_picture)} />
                </div>
              ) : (
                <img
                  src={require("../../assets/empty-profile.PNG")}
                  alt={"alt"}
                  className="h-20 w-20"
                  style={{
                    objectFit: "cover",
                    width: 108,
                    height: 108,
                    position: "absolute",
                    left: 28,
                    top: 179,
                  }}
                />
              )}
              {/*<img
                src={id?.user_picture ? `${id?.user_picture}` : require("../../assets/empty-profile.PNG")}
                alt={"alt"}
                className="h-20 w-20"
                style={{
                  objectFit: "cover",
                  width: 108,
                  height: 108,
                  position: "absolute",
                  left: 28,
                  top: 179,
                }}
              /> */}
              <p
                style={{
                  position: "relative",
                  left: 200,
                  top: 177,
                  fontSize: 14,
                }}
              >{`${id?.first_name?.toUpperCase()} ${id?.last_name?.toUpperCase()}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 247,
                  top: 192,
                  fontSize: 11,
                }}
              >{`${id?.id}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 230,
                  top: 208,
                  fontSize: 11,
                }}
              >{`${id?.barangay?.toUpperCase()} ${id?.city_municipality?.toUpperCase()}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 275,
                  top: 226,
                  fontSize: 11,
                }}
              >{`${Offices?.find(
                (el) => el.OFFICE === id?.assigned_group
              )?.OFFICE
                }`}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const DashboardView = () => {
  const {
    options,
    handleDelete,
    filterInput,
    groupName,
    setStatus,
    status,
    page,
    setPage,
    totalPage,
  } = useContext(PageContext);

  const componentRef = useRef();
  return (
    <div className="flex flex-col h-auto pt-12 m-10">
      <p className="text-2xl text-left mb-5 font-bold">
        BULK ID PRINTING
      </p>
      <div className="flex flex-row items-center">
        <div>
          <Select
            className="py-4 mr-2"
            showSearch
            placeholder="Select Municipality"
            optionFilterProp="children"
            onChange={filterInput}
            value={groupName}
            // defaultValue="all"
            //onSearch={onSearch}
            filterOption={(input, option) =>
              option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {Offices?.length > 0 &&
              Offices.map((item) => (
                <Select.Option
                  value={item.OFFICE}
                  key={item.OFFICE}
                >
                  {item.OFFICE.toUpperCase()}
                </Select.Option>
              ))}
          </Select>
        </div>
        {/* <div>
					<Select
						className="py-4 mr-2"
						showSearch
						placeholder="Select Barangay"
						optionFilterProp="children"
						onChange={(barangay) => setBarangay(barangay)}
						value={barangay}
						// defaultValue="all"
						//onSearch={onSearch}
						filterOption={(input, option) =>
							option.children
								.toLowerCase()
								.indexOf(input.toLowerCase()) >= 0
						}
					>
						{barangays?.users?.length > 0 &&
							barangays.users.map((item) => (
								<Select.Option
									value={item.barangay}
									key={item.barangay}
								>
									{item.barangay}
								</Select.Option>
							))}
					</Select>
				</div> */}
        <div>
          <Select
            className="py-4 mr-2"
            showSearch
            placeholder="Select Status"
            optionFilterProp="children"
            onChange={(status) => setStatus(status)}
            value={status}
            // defaultValue="all"
            //onSearch={onSearch}
            filterOption={(input, option) =>
              option.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value={"approved"} key={"approved"}>
              Approved
            </Select.Option>
            <Select.Option value={"denied"} key={"denied"}>
              Denied
            </Select.Option>
          </Select>
        </div>
      </div>

      <p className="text-left mt-10">Items to print:</p>
      <div className="text-left">
        <p className="mr-5">Pages</p>
        {totalPage !== 0 &&
          [...Array(totalPage)].map((x, i) => (
            <span
              onClick={() => setPage(i + 1)}
              className={`cursor-pointer hover:text-red-200 mr-2 ${page === i + 1 ? "text-blue-500" : ""
                }`}
            >
              {i + 1}
            </span>
          ))}
      </div>
      <div className="border-t-2">
        {options?.length > 0 && (
          <div className="text-left ml-16 text-blue-400 font-bold">
            <ReactToPrint
              trigger={() => <button>Print</button>}
              content={() => componentRef.current}
            />
            <ComponentToPrint
              ref={componentRef}
              selected={options.slice(
                (page - 1) * 200,
                (page - 1) * 200 +
                (page === totalPage
                  ? options.length % 200
                  : 200)
              )}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardView;
