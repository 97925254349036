import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { authGuard, AuthGuard, DashGuard } from "./modules";
import { ApolloProvider } from "@apollo/react-hooks";
import { AppContext } from "../lib/context";
import makeApolloClient from "../lib/apollo";
import login from "../pages/login";
import PendingController from "../pages/pending";
import ShowDetails from "../pages/show-details";
import ApprovedController from "../pages/approved";
import DeniedController from "../pages/denied";
import AcceptedController from "../pages/accepted";
import ScreenedController from "../pages/screened";
import DashboardController from "../pages/dashboard";
import ImportController from "../pages/import";
import AdminReportsController from "../pages/admin-report";
import PrintController from "../pages/print";
import CPGUsersController from "../pages/cpgusers";
import BulkPrintController from "../pages/print-bulk";

export default function App() {
  const [client, setClient] = useState(null);

  const { TOKEN } = useContext(AppContext);

  const initClient = async () => {
    let client;
    const token = localStorage.getItem(process.env.REACT_APP_LS_TOKEN);
    if (token) {
      client = makeApolloClient(token);
    } else {
      client = makeApolloClient(process.env.REACT_APP_DEFAULT_JWT);
    }
    setClient(client);
  };

  useEffect(() => {
    initClient();
  }, []);

  if (!client) return null;

  return (
    <Router>
      <ApolloProvider client={client}>
        <Switch>
          <AuthGuard exact path="/" component={login} />
          <DashGuard
            exact
            path="/admin-reports"
            component={AdminReportsController}
          />
          <DashGuard exact path="/dashboard" component={DashboardController} />
          <DashGuard exact path="/pending" component={PendingController} />
          <DashGuard exact path="/approved" component={ApprovedController} />
          <DashGuard exact path="/denied" component={DeniedController} />
          <DashGuard exact path="/accepted" component={AcceptedController} />
          <DashGuard exact path="/screened" component={ScreenedController} />
          <DashGuard exact path="/show-details/:id?" component={ShowDetails} />
          <DashGuard exact path="/print" component={PrintController} />
          <DashGuard exact path="/print-bulk" component={BulkPrintController} />
          <DashGuard exact path="/import" component={ImportController} />
          <DashGuard exact path="/cpgusers" component={CPGUsersController} />
        </Switch>
      </ApolloProvider>
    </Router>
  );
}
