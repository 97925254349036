import React, { useMemo, useState, useEffect } from "react";
import { PageContext } from "../../lib/context";
import ShowDetailsView from "./view";
import { useParams } from "react-router-dom";
import { USER_DETAILS, GET_USER_NEED_LIST } from "../../lib/query";
import { Form, notification, Button } from "antd";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import {
  UPDATE_CATEGORY,
  UPDATE_USER,
  UPDATE_USER_INFO,
  UPSERT_USER_ITEMS,
  DELETE_USER_ITEMS,
} from "../../lib/mutations";
import S3 from "react-aws-s3";
import { getSignedImageUrl, uploadFile } from "../../lib/util";

const ShowDetails = (props) => {
  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_DIR_NAME /* optional */,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
    s3Url: process.env.REACT_APP_S3_URL,
  };
  const S3Client = new S3(config);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const { id } = useParams();

  const [registrations, setRegistrations] = useState(null);
  const [items, setItems] = useState(null);
  const [certificates, setCertificates] = useState(null);
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(null);
  const [category, setCategory] = useState(null);
  const [updatingPicture, setUpdatingPicture] = useState(false);
  const [picture, setPicture] = useState(null);
  const [user_id_picture, setUserIdPicture] = useState(null);
  const [NeedListCsvLoading, setNeedListCsvLoading] = useState(false);
  const [NeedListCsvData, setNeedListCsvData] = useState([]);
  const [idChecker, setIdChecker] = useState(false);
  const [receivedHelp, setReceiveHelp] = useState(true);
  const [itemFields, setItemFields] = useState([]);
  const [loading, setLoading] = useState(false);

  const { data, loading:needlistcsv } = useQuery(GET_USER_NEED_LIST, {
		variables: {
			userid: id
		},
	});

  useMemo(() => {
    if (registrations?.user_picture) {
      async function getImage() {
        let user_pic = await getSignedImageUrl(registrations?.user_picture);
        let user_id = await getSignedImageUrl(registrations?.user_id_picture);
        setPicture(user_pic);
        setUserIdPicture(user_id);
      }
      getImage();
    }
  }, [registrations]);

  const { data: user_registrations, loading: loading_user_registrations } =
    useQuery(USER_DETAILS, {
      variables: {
        id,
      },
    });

  const [updateUser, { loading: updating_user }] = useMutation(UPDATE_USER);
  const [updateCategory, { loading: updating_category }] =
    useMutation(UPDATE_CATEGORY);
  const [updateUserInfo, { loading: updating_user_info }] =
    useMutation(UPDATE_USER_INFO);
  const [upsertItems] = useMutation(UPSERT_USER_ITEMS);
  const [deleteItems] = useMutation(DELETE_USER_ITEMS);

  useEffect(() => {
    if (user_registrations) {
      const user = user_registrations?.users?.[0];
      form.setFieldsValue({
        ...user,
        items: user?.user_items,
        birth_date: moment(user?.birth_date),
        spouse_birth_date: moment(user?.spouse_birth_date),
        id_expiry: moment(user?.id_expiry),
        receive_date: moment(user?.receive_date),
      });
      setRegistrations(user);
      setItems(user?.user_items);
      setCertificates(user?.user_business_certificates);
      console.log("checker", user_registrations?.users?.[0]?.user_items);
      setItemFields(
        user_registrations?.users?.[0]?.user_items.map((item) => {
          return {
            name: ["item"],
            value: item?.item,
          };
        })
      );
      if (user_registrations?.users?.[0]?.has_valid_id == "No") {
        setIdChecker(true);
      } else {
        setIdChecker(false);
      }

      if (!user_registrations?.users?.length) {
        window.location.href = "/dashboard";
      }
    }
  }, [user_registrations]);

  useEffect(() => {
    console.log("itemFIelds", data);
  }, [data]);


  useMemo(() => {
		if (data) {
			setNeedListCsvLoading(true);
			console.log("data", data);
			const users = data?.user_items;
			const user_length = users?.length;
			const userData = [
				[
          "Name",
          "Item",
          "Quantity",
          "Suggested Supplier/Vendor",
          "Picture Link",
				],
			];
			if (user_length) {
				for (var i = 0; i < user_length; i++) {
					const item = users[i];
          const name = item?.user?.first_name + " " + item?.user?.last_name
					const payload = [
            name,
						item?.item ? item?.item : "",
						item?.quantity ? item?.quantity : "",
						item?.suggested_supplier_vendor ? item?.suggested_supplier_vendor : "",
            item?.user?.user_picture ? item?.user?.user_picture : "" ,
					];
					userData.push(payload);
					if (i === user_length - 1) {
						setNeedListCsvData([...userData]);
						setNeedListCsvLoading(false);
					}
				}
			} else {
				setNeedListCsvData([...userData]);
				setNeedListCsvLoading(false);
			}
		}
	}, [data]);

  const handleRegistration = (status) => {
    setStatus(status);
    setVisible(true);
  };

  const hasId = (e) => {
    //console.log("boo",e)
    if (e == "Yes") {
      setIdChecker(false);
    } else {
      setIdChecker(true);
    }
  };

  const haveReceivedHelp = (e) => {
    //console.log("boo",e)
    if (e == "Yes") {
      setReceiveHelp(false);
    } else {
      setReceiveHelp(true);
    }
  };

  const handleFinishForm = async (data) => {
    setLoading(true);
    console.log("success", id);
    console.log("data", data);

    const items = data.items;
    delete data["items"];

    const res = await updateUserInfo({
      variables: {
        id,
        data: {
          ...data,
          trading: data.trading == undefined ? null : data?.trading.toString(),
          applied_business:
            data.applied_business == undefined
              ? null
              : data?.applied_business.toString(),
          processing_manufacturing:
            data.processing_manufacturing == undefined
              ? null
              : data?.processing_manufacturing.toString(),
          service_establishments:
            data.service_establishments == undefined
              ? null
              : data?.service_establishments.toString(),
          business_certificates:
            data.business_certificates == undefined
              ? null
              : data?.business_certificates.toString(),
        },
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      if (id) {
        if (items) {
          const new_items_object = items.map(function (el) {
            var o = Object.assign({}, el);
            delete o.__typename;
            o.user_id = id;
            return o;
          });
          const response = await deleteItems({
            variables: {
              userid: id,
            },
          });
          if (
            response?.data?.delete_user_items &&
            new_items_object.length > 0
          ) {
            const itemresponse = await upsertItems({
              variables: {
                object: [...new_items_object],
              },
            });
            if (itemresponse?.data?.insert_user_items?.affected_rows) {
              notification.success({
                message: "Successfully updated info.",
              });
              setLoading(false);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            } else {
              notification.error({
                message: "Failed to update items",
              });
              setLoading(false);
            }
          } else {
            notification.success({
              message: "Successfully updated info.",
            });
            setLoading(false);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        }
      }
    } else {
      notification.error({
        message: "Failed to update user info",
      });
      setLoading(false);
    }
    // if (status !== "deny") {
    //   let res = await updateUser({
    //     variables: {
    //       wallet_balance: parseFloat(registrations?.wallet_balance),
    //       status: status,
    //       id,
    //     },
    //   });
    //   if (res?.data?.update_users?.affected_rows) {
    //     notification.success({
    //       message: "Registration has been " + status,
    //     });
    //     setTimeout(() => {
    //       window.location.href = "/dashboard";
    //     }, 1000);
    //   } else {
    //     notification.error({
    //       message: "Failed to approve",
    //     });
    //   }
    // } else {
    //   let res = await updateUser({
    //     variables: {
    //       wallet_balance: parseFloat(0),
    //       status: "denied",
    //       id,
    //     },
    //   });
    //   if (res?.data?.update_users?.affected_rows) {
    //     notification.success({
    //       message: "Registration has been denied",
    //     });
    //     setTimeout(() => {
    //       window.location.href = "/dashboard";
    //     }, 1000);
    //   } else {
    //     notification.error({
    //       message: "Failed to denied",
    //     });
    //   }
    // }
  };

  const renderAcceptApproveScreenButton = (status, btntext) => {
    return (
      <Button
        disabled={updating_user || updating_user_info || updating_category}
        type="primary"
        className="text-center mr-2"
        onClick={() => handleRegistration(status)}
      >
        {btntext}
      </Button>
    );
  };

  const renderDenyButton = () => {
    return (
      <Button
        disabled={updating_user || updating_user_info || updating_category}
        type="primary"
        className="text-center bg-red-400 border-red-400"
        onClick={() => handleRegistration("deny")}
      >
        Deny
      </Button>
    );
  };

  const renderButtons = () => {
    const user_info = JSON.parse(localStorage.getItem("portal_user_info"));
    if (user_info?.permission === "bm") {
      switch (registrations?.status) {
        case "pending":
          return (
            <>
              {renderAcceptApproveScreenButton("accept", "Accept")}
              {renderDenyButton()}
            </>
          );

        case "accept":
          return <>{renderDenyButton()}</>;

        default:
          return <></>;
      }
    }

    if (user_info?.permission === "chamber") {
      switch (registrations?.status) {
        case "accept":
          return (
            <>
              {renderAcceptApproveScreenButton("screened", "Screen")}
              {renderDenyButton()}
            </>
          );

        case "screened":
          return (
            <>
              {renderAcceptApproveScreenButton("approved", "Approve")}
              {renderDenyButton()}
            </>
          );

        default:
          return <></>;
      }
    }

    if (user_info?.permission === "committee") {
      switch (registrations?.status) {
        case "approved":
          return (
            <>
              {renderAcceptApproveScreenButton("passed", "Pass application")}
              {renderDenyButton()}
            </>
          );
        case "denied":
          return (
            <>{renderAcceptApproveScreenButton("passed", "Pass application")}</>
          );

        default:
          return <></>;
      }
    }
  };

  const handleSaveCategory = async () => {
    if (!id || !category) return;
    const res = await updateCategory({
      variables: {
        id,
        category,
      },
    });
    if (res?.data?.update_users?.affected_rows > 0) {
      notification.success({
        message: "Successfully updated category",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({
        message: "Failed to update category",
      });
    }
  };

  const handleSave = async (data) => {
    const res = await updateUserInfo({
      variables: {
        id,
        data: { ...data },
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Successfully updated info",
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      notification.error({
        message: "Failed to update user info",
      });
    }
  };

  const handleUpdateInfo = async () => {
    let res = await updateUser({
      variables: {
        wallet_balance: parseFloat(0),
        status: "denied",
        id,
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Form updated successfully",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({
        message: "Failed to update",
      });
    }
  };

  const handleSetToPending = async () => {
    let res = await updateUser({
      variables: {
        wallet_balance: parseFloat(0),
        status: "pending",
        id,
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Registration has been set to PENDING",
      });
      setTimeout(() => {
        window.location.href = "/pending";
      }, 1000);
    } else {
      notification.error({
        message: "Failed to set to pending",
      });
    }
  };

  const handleSetToDenied = async () => {
    let res = await updateUser({
      variables: {
        wallet_balance: parseFloat(0),
        status: "denied",
        id,
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Registration has been set to DENIED",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      notification.error({
        message: "Failed to set to denied",
      });
    }
  };

  const handleSetToApprove = async () => {
    let res = await updateUser({
      variables: {
        wallet_balance: parseFloat(0),
        status: "approved",
        id,
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Registration has been set to Approve",
      });
      setTimeout(() => {
        window.location.href = "/approved";
      }, 1000);
    } else {
      notification.error({
        message: "Failed to set to approve.",
      });
    }
  };

  const getCategory = (category) => {
    const categories = {
      a: "NEGOSYONG PADAYONON CATEGORY",
      b: "PRODUKTONG SUGBOANON CATEGORY",
      c: "MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY",
    };
    return categories[category];
  };

  const handleUserImageChange = async (file) => {
    setUpdatingPicture(true);
    // const { location: user_picture } = await S3Client.uploadFile(
    //   file,
    //   "pic-" + moment().format("YYYY-MM-DD h:m:s").toString()
    // );
    //const { data: user_picture } = await uploadFile(file);
    let { data: user_picture } = await uploadFile(file);
    //setUpdatingPicture(false);
    //console.log("pic", user_picture);
    //return;
    const res = await updateUserInfo({
      variables: {
        id,
        data: {
          user_picture: user_picture.secure_url,
        },
      },
    });
    if (res?.data?.update_users?.affected_rows) {
      notification.success({
        message: "Successfully updated photo",
      });
      setUpdatingPicture(false);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 400);
    } else {
      setUpdatingPicture(false);
      notification.error({
        message: "Failed to update user photo",
      });
    }
  };

  const pageValues = {
    form,
    items,
    certificates,
    visible,
    setVisible,
    status,
    handleRegistration,
    form2,
    handleFinishForm,
    registrations,
    renderButtons,
    category,
    setCategory,
    handleSaveCategory,
    updating_category,
    handleSave,
    updating_user_info,
    handleSetToPending,
    getCategory,
    loading,
    handleSetToDenied,
    handleSetToApprove,
    handleUserImageChange,
    handleUpdateInfo,
    updatingPicture,
    picture,
    idChecker,
    user_id_picture,
    haveReceivedHelp,
    receivedHelp,
    hasId,
    itemFields,
    NeedListCsvLoading, 
    setNeedListCsvLoading,
    NeedListCsvData, 
    setNeedListCsvData,
  };
  return (
    <PageContext.Provider value={pageValues}>
      <ShowDetailsView />
    </PageContext.Provider>
  );
};

export default ShowDetails;
